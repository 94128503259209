import './summary-price-overview-total-block.component.scss'
import { Button } from "../../../button/button";
import { useDispatch as useReduxDispatch, useSelector } from "react-redux";
import { LocalStorageService } from '../../../../services/local-storage.service';
import { PriceWarranty } from '../../../price-warranty/price-warranty';
import { useEffect, useMemo, useState } from 'react';
import { flightAirlineOperator } from '../../../../common/enums/flightOptions';
import { isDateDifferenceHigher } from '../../../../services/date-formatter.service';
import { affiliates } from '../../../../common/enums/affiliates';
import { GetAffiliate } from '../../../../services/general.service';

export const SummaryPriceOverviewTotalBlockComponent = ({
    contentfulHolidayPageSummary,
    contentfulButtons,
    Icon,
    detailedHoliday,
    performCheckAvailability
}) => {
    const holidayKey = useSelector(state => state.holidayData.holidayKey);
    const [isTransaviaAirlineCode, setIsTransaviaAirlineCode] = useState(null);

    useEffect(() => {
        const selectedFlightOption = detailedHoliday?.flightOptionsInfo?.flightOptions?.find(option => option.selected);
        const inboundFlight = selectedFlightOption?.inboundLeg.airlineCode === flightAirlineOperator.TRANSAVIA_Flight;
        const outboundFlight = selectedFlightOption?.outboundLeg.airlineCode === flightAirlineOperator.TRANSAVIA_Flight;
        setIsTransaviaAirlineCode(inboundFlight && outboundFlight)
    }, [detailedHoliday])

    const isPriceWarrantyDateValid = useMemo(() => {
        return isDateDifferenceHigher()
    }, [])

    const onclickCheckAvailability = (index) => {
        const checkAvailabilityRequestBody = {
            cultureCode: LocalStorageService.getCulture(),
            holidayKey: holidayKey
        };
        performCheckAvailability(checkAvailabilityRequestBody);
    }

    return <div className="summary-price-overview-total-block-component">
              { isTransaviaAirlineCode &&  isPriceWarrantyDateValid && 
                GetAffiliate() === affiliates.transavia &&
                <div className="holiday-card-amenity summary-price-warranty">
                  <PriceWarranty />
                </div>
              }
        <div className="price-block">
            <div
                className="price-block-total">{contentfulHolidayPageSummary && contentfulHolidayPageSummary[0]?.fields?.total}</div>
            <div className="price-block-amount">{'€'} {detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.total}</div>
        </div>
        {
            detailedHoliday?.holidayDetailInfo?.priceDetailInfo?.downPaymentPossible &&
            <div
                className="down-payment-option">
                <div className="price-overview-row">
                    <div> {contentfulHolidayPageSummary && contentfulHolidayPageSummary[0]?.fields?.downPayment}</div>
                </div>

            </div>
        }
        <Button className="w-100 mr-0 ml-0 top-0 pd-b button-accept holiday-package-button"
            onClickAction={onclickCheckAvailability}
            buttonText={contentfulButtons && contentfulButtons[0]?.fields?.checkAvailability}
        ></Button>
    </div>
}
