import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CartIcon } from "../../assets/icons/klm/cart.svg";
import { ReactComponent as CartIconTransavia } from "../../assets/icons/transavia/cart-transavia.svg";
import { contentType } from "../../common/constants/contentType";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate } from "../../services/general.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import "./summary-bar.scss";

const SummaryBar = ({ className, onCartClick }) => {
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);
  const contentfulEntries = useContext(ContentfulContext);
  const [contentfulHolidayPageSummary, setContentfulHolidayPageSummary] =
    useState(null);

  useEffect(() => {
    const contentSummary = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE_SUMMARY
    );
    setContentfulHolidayPageSummary(contentSummary);
  }, [contentfulEntries]);

  return (
    <div className={`summary-bar ${className} sticky-shopping-cart`}>
      <div className="summary-bar-body">
        <div className="summary-bar-body-icon" onClick={onCartClick}>
          <p>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.viewCart}
          </p>
          {GetAffiliate() === "KLM" ? (
            <CartIcon />
          ) : GetAffiliate() === "TRANSAVIA" ? (
            <CartIconTransavia />
          ) : null}
        </div>
        <p className="summary-bar-price">
          <span>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0].fields.total}
          </span>
          {" € " + checkOutDataCart?.shoppingCart?.priceInfo?.total}
        </p>
      </div>
    </div>
  );
};

export default SummaryBar;
