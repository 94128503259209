import { affiliates } from "../common/enums/affiliates";
import { GetAffiliate } from "./general.service";
import { LocalStorageService } from "./local-storage.service";
import config from "./../environments/config.json";
import { differenceInDays, format, parseISO } from "date-fns";
import { LocalStorageEnum } from "../common/enums/localStorageEnum";


export const formatSelectedDatesForRequest = (date) => {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + selectedDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  export const formatSelectedDates = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    if (date) {
      return new Date(date).toLocaleDateString(
        LocalStorageService.getCulture(),
        options
      );
    }
  };

  export const formatWeekDay = () => {
    return (nameOfDay) =>
      GetAffiliate() === affiliates.klm
        ? nameOfDay.substr(0, 1).toLocaleUpperCase()
        : nameOfDay.substr(0, 2);
  };

  export const minAndMaxDateCalendarHelper = (startDate, endDate, currentDate) => {
    let minDateOfAll;
    let maxDateOfAll;
  
    if (startDate && !endDate) {
      minDateOfAll = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      maxDateOfAll = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + (config.MAX_TRIP_DURATION - 1)
      );
    } else {
      minDateOfAll = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 4
      );
      maxDateOfAll = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );
    }

    return [minDateOfAll, maxDateOfAll]
  }

  export const findAvailableDate = (hoveredDate, priceCalendarData) => {
    const date = new Date(hoveredDate);
    const formattedDate = format(date, "yyyy-MM-dd");
    const searchedData = priceCalendarData.availableDates?.find(el => el.departureDate === formattedDate);
    return searchedData
  }

  export const isDateDifferenceHigher = (totalDays = config.HV_PRICE_WARRANTY_DURATION) => {
    const selectedDates = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );
    const currentDate = new Date();
    const departureDate = parseISO(selectedDates?.departureDate);
    const dateRange = differenceInDays(departureDate, currentDate);
    return dateRange > totalDays;
  };
  