import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { contentType } from "../../common/constants/contentType";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { paymentTypes } from "../../common/enums/paymentTypes";
import CheckoutCard from "../../components/checkout-card/checkout-card";
import ConfirmationSuccess from "../../components/confirmation-success/confirmation-success";
import FlightsAndArrivals from "../../components/flights-and-arrivals/flights-and-arrivals";
import { PagesStepperBarComponent } from "../../components/pages-stepper-bar/pages-stepper-bar.component";
import { PaymentHotelSummaryOverviewComponent } from "../../components/payment-hotel-summary-overview/payment-hotel-summary-overview.component";
import { ShoppingCartComponent } from "../../components/shopping-cart/shopping-cart.component";

import { getContentfulByContentType } from "../../services/contentful.service";
import { GetApiUrl } from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import { getCheckOutDetailsCart } from "../../store/shopping/slice";
import "./confirmation-page.component.scss";
import { FailBookingComponent } from "./fail-booking/fail-booking.component";
//responsiveness page
import { breakpoints } from "../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { PaymentStateEnum } from "../../common/enums/orderStateEnum";
import { PendingBookingComponent } from "./pending-booking/pending-booking.component";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { AnalyticsService } from "../../services/analytics/analytics.service";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.PAYMENT_PAGE:
      return { ...state, contentfulPaymentPage: action.payload };
    case contentfulEntriesReducer.ROOM_ALLOCATION:
      return { ...state, contentfulRoomAllocation: action.payload };
    case contentfulEntriesReducer.BUTTONS:
      return { ...state, contentfulButtons: action.payload };
    case contentfulEntriesReducer.CONFIRMATION_PAGE:
      return { ...state, contentfulConfirmationPage: action.payload };
    case contentfulEntriesReducer.HOLIDAY_PAGE:
      return { ...state, contentfulHolidayPage: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const ConfirmationPageComponent = ({}) => {
  const [isMobile, setIsMobile] = useState(false);
  const windowSizeDetector = useWindowSizeDetector();

  const dispatch = useDispatch();
  const contentfulEntries = useContext(ContentfulContext);
  const [
    {
      contentfulPaymentPage,
      contentfulHolidayPage,
      contentfulRoomAllocation,
      contentfulConfirmationPage,
    },
    dispatchContentful,
  ] = useReducer(reducer, initialState);
  const [paymentConfirmation, setPaymentConfirmation] = useState(null);
  const [isOrderBookingFailed, setOrderBookingFailed] = useState(null);
  const holidayKeyPayment =
    LocalStorageService.getLocalStorageItem(LocalStorageEnum.LAST_HOLIDAY_KEY);
  const bookingStatus = paymentConfirmation?.paymentDetails?.paymentStatus;


  const checkoutData = useSelector((state) => state.checkOut.checkOutData);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);
  const travellersData = checkoutData?.travellerInfo?.travellers || [];
  const mainbookerData = checkoutData || [];
  const homestayerData = checkoutData?.contactDetailInfo?.homeStayer || [];
  const [expandedType, setExpandedType] = useState(null);
  const [analyticsActionTrigger, setAnalyticsActionTrigger] = useState(false);

  const toggleExpand = (newType) =>
    setExpandedType((prevType) => (prevType === newType ? null : newType));

  const flightData =
    checkoutData?.checkOutSummary?.flightDetails?.outboundFlight || [];
  const arrivalData =
    checkoutData?.checkOutSummary?.flightDetails?.inboundFlight || [];

  const getPaymentConfirmation = async () => {
    const response = await axios.get(
      `${GetApiUrl()}/PaymentConfirmation/${holidayKey}`
    );
    const confirmationPaymentData = response?.data;
    if (
      !confirmationPaymentData?.orderDetails?.orderBooked &&
      confirmationPaymentData?.paymentDetails?.paymentConfirmed
    ) {
      setOrderBookingFailed(true);
    } else {
      setOrderBookingFailed(false);
    }
    setPaymentConfirmation(response.data);
  };

  useEffect(() => {
    getPaymentConfirmation();
  }, []);

  useEffect(() => {
    if (checkoutData && !analyticsActionTrigger) {
      AnalyticsService.handleEvents(
        [
          {
            platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
            type: AnalyticsService.analyticsConstants.eventType.pageView,
            name: AnalyticsService.analyticsConstants.apiEvents.confirmation,
            data: checkoutData.checkOutKey
          },
          {
            platform: AnalyticsService.analyticsConstants.platform.gtag,
            type: AnalyticsService.analyticsConstants.eventType.pageView,
            data: AnalyticsService.analyticsConstants.apiEvents.confirmation
          }
        ]
      );
      setAnalyticsActionTrigger(true);
    }
  }, [checkoutData]);

  useEffect(() => {
    const isMobileSize =
      windowSizeDetector.width > breakpoints.XXS &&
      windowSizeDetector.width < breakpoints.SM;

    setIsMobile(isMobileSize);
  }, [windowSizeDetector.width]);


  const contentfulPassengerDetailsPageResponse = () => {
    const contentfulPaymentPage = getContentfulByContentType(
      contentfulEntries,
      contentType.PAYMENT_PAGE
    );
    const contentfulRoomAllocation = getContentfulByContentType(
      contentfulEntries,
      contentType.ROOM_ALLOCATION
    );
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );

    const contentfulConfirmationPage = getContentfulByContentType(
      contentfulEntries,
      contentType.CONFIRMATION_PAGE
    );

    const contentfulHolidayPage = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE
    );

    dispatchContentful({
      type: contentfulEntriesReducer.PAYMENT_PAGE,
      payload: contentfulPaymentPage,
    });

    dispatchContentful({
      type: contentfulEntriesReducer.ROOM_ALLOCATION,
      payload: contentfulRoomAllocation,
    });

    dispatchContentful({
      type: contentfulEntriesReducer.BUTTONS,
      payload: contentfulButtons,
    });

    dispatchContentful({
      type: contentfulEntriesReducer.CONFIRMATION_PAGE,
      payload: contentfulConfirmationPage,
    });
    dispatchContentful({
      type: contentfulEntriesReducer.HOLIDAY_PAGE,
      payload: contentfulHolidayPage,
    });
  };

  useEffect(() => {
    contentfulPassengerDetailsPageResponse();
  }, [contentfulEntries]);

  useEffect(() => {
    dispatch(getCheckOutDetailsCart(holidayKey));
  }, [dispatch, holidayKey]);

  const getMessageBlockComponent = () => {
    if (bookingStatus === PaymentStateEnum.PENDING) {
      return (
        <PendingBookingComponent
          contentfulConfirmationPage={contentfulConfirmationPage}
          destinationCity={checkoutData?.checkOutSummary?.destination}
        />
      );
    }
  
    if (paymentConfirmation?.paymentDetails?.paymentConfirmed 
        && paymentConfirmation?.orderDetails?.orderBooked) {
      return (
        <ConfirmationSuccess
          contentfulConfirmationPage={contentfulConfirmationPage}
          paymentConfirmation={paymentConfirmation}
        />
      );
    }
  
    return (
      <FailBookingComponent
        contentfulConfirmationPage={contentfulConfirmationPage}
        destinationCity={checkoutData?.checkOutSummary?.destination}
      />
    );
  };

  return (
    <div className="confirmation-page-component">
      <PagesStepperBarComponent />

      <div className="confirmation-page-container">
        <div className="left-side">
          {getMessageBlockComponent()}
          <PaymentHotelSummaryOverviewComponent
            contentfulPaymentPage={contentfulPaymentPage}
            contentfulRoomAllocation={contentfulRoomAllocation}
            hotelInfo={checkoutData?.shoppingCart?.hotelInfo}
            checkOutSummary={checkoutData?.checkOutSummary}
            isOrderBookingFailed={isOrderBookingFailed}
          />
          <div
            className={`checkout-component ${
              isOrderBookingFailed ? "fail-booking-blur" : ""
            }`}
          >
            <div className="flights-arrivals-container">
              <FlightsAndArrivals
                type={paymentTypes.FLIGHTS}
                data={flightData}
                isMobile={isMobile}
                title={
                  contentfulPaymentPage &&
                  contentfulPaymentPage[0].fields.departureFlight
                }
                contentfulPaymentPage={contentfulPaymentPage}
                contentfulHolidayPage={contentfulHolidayPage}
                expandedType={expandedType}
                toggleExpand={toggleExpand}
              />
              <FlightsAndArrivals
                type={paymentTypes.ARRIVALS}
                data={arrivalData}
                isMobile={isMobile}
                title={
                  contentfulPaymentPage &&
                  contentfulPaymentPage[0].fields.arrivalFlight
                }
                contentfulPaymentPage={contentfulPaymentPage}
                contentfulHolidayPage={contentfulHolidayPage}
                expandedType={expandedType}
                toggleExpand={toggleExpand}
              />
            </div>
            <CheckoutCard
              type={paymentTypes.TRAVELLER}
              typeTraveller={paymentTypes.TRAVELLER}
              typeHomeStayer={paymentTypes.HOMESTAYER}
              typeMainBooker={paymentTypes.MAINBOOKER}
              dataTraveller={travellersData}
              dataHomeStayer={homestayerData}
              dataMainBooker={mainbookerData}
              checkFlightOption={flightData}
              titleTraveller={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.travelerData
              }
              titleTravellerNotDriver={
                contentfulHolidayPage &&
                contentfulHolidayPage[0]?.fields?.travellerDataNotDriver
              }
              titleHomeStayer={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.stayingHome
              }
              titleMainBooker={
                contentfulPaymentPage &&
                contentfulPaymentPage[0]?.fields?.contactInformation
              }
              contentfulPaymentPage={contentfulPaymentPage}
              contentfulHolidayPage={contentfulHolidayPage}
              travellerBaggageInfo={
                checkoutData?.additionalProductInfo?.ancillaryServiceInfo
              }
            />
          </div>
        </div>
        <div
          className={`shopping-cart-container ${
            isOrderBookingFailed ? "fail-booking-blur" : ""
          }`}
        >
          <ShoppingCartComponent checkOutData={checkoutData} />
        </div>
      </div>
    </div>
  );
};
