import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { GetMealType } from "../../common/enums/meal-types";
import "./holiday-package-options-summary.component.scss";

export const HolidayPackageOptionsSummaryComponent = ({
  holidayHeader,
  contentfulHolidayPage,
  searchBarRef,
}) => {
  const [dataToDisplay, setStateDataToDisplay] = useState(null);
  const [textToDisplay, setStateTextToDisplay] = useState(null);
  const [showDetails, setStateShowDetails] = useState(true);
  const windowSize = useWindowSizeDetector();
  const scrollPositionOffset = 100;

  useEffect(() => {
    windowSize.width > breakpoints.SM
      ? setStateShowDetails(true)
      : setStateShowDetails(false);
  }, [windowSize.width]);

  useEffect(() => {
    if (holidayHeader) {
      setStateDataToDisplay(holidayHeader);
    }
  }, [holidayHeader]);

  useEffect(() => {
    if (contentfulHolidayPage) {
      setStateTextToDisplay(
        contentfulHolidayPage[0]?.fields?.packageOptionsSummary
      );
    }
  }, [contentfulHolidayPage]);

  const setShowDetails = () => {
    setStateShowDetails(!showDetails);
  };

  const scrollToSearchBar = () => {
      searchBarRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <div className="holiday-package-options-summary-component">
      <div className="content">
        {showDetails ? (
          <>
            <div className="column">
              <div className="item mobile">
                <div className="mobile-content">
                  {textToDisplay ? textToDisplay.yourPackage : "Your package"}
                </div>
              </div>
              <div className="item">
                <div className="icon"></div>
                <div className="text bold">
                  {dataToDisplay ? dataToDisplay.holidayLocation : "Location"},
                </div>
                <div className="text">
                  {dataToDisplay ? dataToDisplay.hotelName : "Hotel name"}
                </div>
              </div>
              <div className="item">
                <div className="icon"></div>
                <div className="text">
                  {dataToDisplay
                    ? GetMealType(dataToDisplay.mealType)
                    : "Meal type"}
                </div>
              </div>
              <div className="item">
                <div className="icon"></div>
                <div className="text">
                  {dataToDisplay
                    ? dataToDisplay.travelers +
                      textToDisplay?.travelers +
                      dataToDisplay.rooms +
                      textToDisplay?.rooms
                    : "Number of travelers and rooms"}
                </div>
              </div>
            </div>

            <div className="column">
              <div className="item">
                <div className="icon"></div>
                <div className="text bold">
                  {dataToDisplay
                    ? dataToDisplay.departureAirport
                    : "Departure airport"}
                </div>
                <div className="text">
                  {dataToDisplay
                    ? dataToDisplay.departureDate
                    : "Departure date"}
                </div>
              </div>
              <div className="item">
                <div className="icon"></div>
                <div className="text bold">
                  {dataToDisplay
                    ? dataToDisplay.returnAirport
                    : "Return airport"}
                </div>
                <div className="text">
                  {dataToDisplay ? dataToDisplay.returnDate : "Return date"}
                </div>
              </div>
              <div className="item">
                <div className="icon"></div>
                <div className="text">
                  {dataToDisplay
                    ? dataToDisplay.tripDuration + textToDisplay?.days
                    : "Trip duration"}
                  {` (${dataToDisplay?.numberOfNights} 
                      ${
                        contentfulHolidayPage &&
                        contentfulHolidayPage[0].fields.trip.nights
                      })`}
                </div>
              </div>
              <div className="item mobile center-content">
                <div className="view-switch" onClick={setShowDetails}>
                  <div className="label size-large">
                    {textToDisplay
                      ? textToDisplay.closeDetails
                      : "Close details"}
                  </div>
                  <div className="icon size-large">
                    <div
                      className={
                        showDetails ? "icon-drop-up" : "icon-drop-down"
                      }
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="column">
          <div className="section">
            <div className="view-switch mobile" onClick={setShowDetails}>
              <div className="label size-small">
                {textToDisplay ? textToDisplay.details : "Details"}
              </div>
              <div className="icon size-small">
                <div
                  className={showDetails ? "icon-drop-up" : "icon-drop-down"}
                ></div>
              </div>
            </div>
            <div className="price-info">
              <span>
                {textToDisplay ? textToDisplay.priceInfo[0] : "from "}
              </span>
              <span>
                {dataToDisplay && dataToDisplay?.pricePerPerson
                  ? "€ " + dataToDisplay.pricePerPerson
                  : "Price per person"}
              </span>
              <span>
                {textToDisplay ? textToDisplay.priceInfo[1] : "/ p.p."}
              </span>
            </div>
            <div className="button-edit" onClick={scrollToSearchBar}>
              <div></div>
              <div>{textToDisplay ? textToDisplay.editPackage[0] : "Edit"}</div>
              <div>
                {textToDisplay ? textToDisplay.editPackage[1] : " package"}
              </div>
            </div>
          </div>
          <div className="section">
            <span>
              {textToDisplay
                ? textToDisplay.priceInfo[2]
                : "Price p.p. is incl. "}
            </span>
            <span>
              {textToDisplay
                ? textToDisplay.priceInfo[3]
                : "local fees to be paid "}
            </span>
            <span>
              {textToDisplay ? textToDisplay.priceInfo[4] : "and excl. "}
            </span>
            <span>
              {textToDisplay
                ? textToDisplay.priceInfo[5]
                : " in booking costs, SGR contribution and Calamity Fund."}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
