import { useEffect } from "react";
import { DropdownComponent } from "../../dropdown/dropdown.component";
import config from "./../../../environments/config.json";
import { languageDictionary } from "../../../common/constants/language";
import { breakpoints } from "../../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";

export const LanguageSelectorComponent = ({
  queryParameters,
  culture,
  language,
  handleLanguageChange,
  isMobile,
  setIsMobile,
}) => {
  const languages = config.AVAILABLE_LANGUAGES;
  const windowSizeDetector = useWindowSizeDetector();

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  }, [windowSizeDetector.width, setIsMobile]);

  return (
    <div className="language-selector-component">
      <DropdownComponent
        defaultPlaceholder={
          <div className="language-input">
            <img
              className="flag-small"
              alt={`${language}`}
              src={`${config.COUNTRY_FLAGS}/${culture
                ?.substring(culture?.length - 2)
                .toLocaleLowerCase()}.png`}
            />
            <span>
              {isMobile
                ? culture?.substring(0, 2).toUpperCase()
                : languageDictionary[culture]}
            </span>
          </div>
        }
      >
        {(closeDropdown) => (
          <div>
            {languages.map((lang, i) => (
              <div
                className={`language-row ${
                  lang === culture ? "selected-in-dropdown-row" : ""
                }`}
                key={i}
              >
                <div
                  className={`language-selection-highlight-bar ${
                    lang === culture ? "selected-language-in-dropdown" : ""
                  }`}
                ></div>

                <div
                  className="language-row-content"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLanguageChange(lang);
                    closeDropdown();
                  }}
                >
                  <img
                    className="flag-small"
                    alt={`${lang}`}
                    src={`${config.COUNTRY_FLAGS}/${lang
                      ?.substring(lang.length - 2)
                      .toLocaleLowerCase()}.png`}
                  />
                  <span>
                    {isMobile
                      ? lang.substring(0, 2).toUpperCase()
                      : languageDictionary[lang]}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </DropdownComponent>
    </div>
  );
};
