import "./price-matrix-header.scss";

import klmChevronLeft from "../../assets/icons/klm/chevron-left-S.svg";
import klmChevronRight from "../../assets/icons/klm/chevron-right-S.svg";
import transaviaChevronLeft from "../../assets/icons/transavia/chevron-left-S.svg";
import transaviaChevronRight from "../../assets/icons/transavia/chevron-right-S.svg";
import chevronLeftDisabled from "../../assets/icons/common/chevron-left-disabled.svg";
import chevronRightDisabled from "../../assets/icons/common/chevron-right-disabled.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { useEffect, useState } from "react";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { affiliateStyleRules } from "../../common/affiliate-style-rules";

export const PriceMatrixHeader = ({
  changeSelectedMonthForPriceMatrix,
  priceMatrixInfo,
  isLoading,
  setIsLoading,
}) => {
  const defaultNumberOfMonthsToShow = 8;
  const [numberOfMonthsForViewport, setNumberOfMonthsForViewport] = useState(
    defaultNumberOfMonthsToShow
  );
  const [enableLeftButton, setEnableLeftButton] = useState(false);
  const [enableRightButton, setEnableRightButton] = useState(true);
  const [monthsToShow, setMonthsToShow] = useState([]);
  const [monthDisplayOffset, setMonthDisplayOffset] = useState(0);
  const windowSizeDetector = useWindowSizeDetector();

  const monthsForExtraExtraLargeViewport = 8;
  const monthsForExtraLargeViewport = 6;
  const monthsForLarge1100Viewport = 6;
  const monthsForLargeViewport = 5;
  const monthsForMediumViewport = 4;
  const monthsForSmallViewport = 3;
  const monthsForMobileViewport = 2;

  useEffect(() => {
    switch (true) {
      case windowSizeDetector.width <= breakpoints.XS:
        setNumberOfMonthsForViewport(monthsForMobileViewport);
        break;
      case windowSizeDetector.width <= breakpoints.SM:
        setNumberOfMonthsForViewport(monthsForSmallViewport);
        break;
      case windowSizeDetector.width <= breakpoints.MD:
        setNumberOfMonthsForViewport(monthsForMediumViewport);
        break;
      case windowSizeDetector.width <= breakpoints.LG:
        setNumberOfMonthsForViewport(monthsForLargeViewport);
        break;
      case windowSizeDetector.width <= breakpoints.LG1100:
        setNumberOfMonthsForViewport(monthsForLarge1100Viewport);
        break;
      case windowSizeDetector.width <= breakpoints.XL:
        setNumberOfMonthsForViewport(monthsForExtraLargeViewport);
        break;
      default:
        setNumberOfMonthsForViewport(monthsForExtraExtraLargeViewport);
        break;
    }
  }, [windowSizeDetector]);

  useEffect(() => {
    if (priceMatrixInfo) {
      const monthData = priceMatrixInfo?.months;
      if (monthData) {
        const endMonthIndex = Math.min(
          monthDisplayOffset + numberOfMonthsForViewport,
          monthData.length
        );
        const startMonthIndex = Math.max(0, endMonthIndex - numberOfMonthsForViewport);
  
        if (endMonthIndex >= monthData.length) {
          setEnableRightButton(false);
        } else {
          setEnableRightButton(true);
        }
        const newMonthsToShow = monthData.slice(startMonthIndex, endMonthIndex);
        setMonthsToShow(newMonthsToShow);
      }
    }
  }, [priceMatrixInfo, monthDisplayOffset, numberOfMonthsForViewport, isLoading]);
  
  useEffect(() => {
    if (priceMatrixInfo) {
      const monthData = priceMatrixInfo?.months;
  
      if (monthData) {
        const isAtStart = monthDisplayOffset === 0;
        const isAtEnd = monthDisplayOffset + numberOfMonthsForViewport >= monthData.length;
  
        setEnableLeftButton(!isAtStart);
      }
    }
  }, [monthDisplayOffset, numberOfMonthsForViewport, priceMatrixInfo]);  
  

  useEffect(() => {
    if (priceMatrixInfo) {
      const monthData = priceMatrixInfo?.months;
      if (monthData) {
        const initialMonthIndex = monthData.findIndex(
          (month) => month.selected
        );
        setMonthDisplayOffset(initialMonthIndex >= 0 ? initialMonthIndex : 0);
      }
    }
  }, [priceMatrixInfo]);

  useEffect(() => {
    if (isLoading) {
      setEnableLeftButton(false);
      setEnableRightButton(false);
    } else {
      const isAtStart = monthDisplayOffset === 0;
      const isAtEnd = monthDisplayOffset ===  priceMatrixInfo.months.length - 1;
  
      setEnableLeftButton(!isAtStart);
      setEnableRightButton(!isAtEnd);
    }
  }, [isLoading, monthDisplayOffset, numberOfMonthsForViewport, priceMatrixInfo.months.length]);  
 
  const HandleMonthClicked = async (selectedPeriod) => {
  
    if (!isLoading) {
      setIsLoading(true);
      await changeSelectedMonthForPriceMatrix(selectedPeriod);
      setMonthsToShow((prevMonths) => {
        const updatedMonths = prevMonths.map((month) =>
          month === selectedPeriod
            ? { ...month, selected: true }
            : { ...month, selected: false }
        );
        return updatedMonths;
      });
      setIsLoading(false);
    }
  };

  const HandleClickedNavigateLeft = () => {
    if (monthDisplayOffset - 1 >= 0) {
      const newOffset = monthDisplayOffset - 1;
      setMonthDisplayOffset(newOffset);
      setEnableRightButton(true);
  
      const selectedMonth = priceMatrixInfo.months[newOffset];
      HandleMonthClicked(selectedMonth);
  
      if (newOffset === 0) {
        setEnableLeftButton(false);
      }
    }
  };


  const HandleClickedNavigateRight = () => {

    const newOffset = monthDisplayOffset + 1;
    const isLastVisibleRange = newOffset + numberOfMonthsForViewport >= priceMatrixInfo.months.length;

    if (newOffset < priceMatrixInfo.months.length) {
      const selectedMonth = priceMatrixInfo.months[newOffset];
  
      setMonthDisplayOffset(newOffset); 
      HandleMonthClicked(selectedMonth); 
  
      setEnableRightButton(!isLastVisibleRange);
      setEnableLeftButton(true);
    } else {
      setEnableRightButton(false);
    }
  };
  
  const GetDisplayMonthName = (displayDate) => {
    return displayDate.split(" ")[0];
  };

  const GetDisplayYearName = (displayDate) => {
    return displayDate.split(" ")[1];
  };

  const GetAffiliateTextColour = () => {
    return GetAffiliate() == affiliates.klm ? "black" : "white";
  };

  return (
    <div className="price-matrix-header-container">
      <div
        className="price-matrix-header-month-navigation-button"
        style={{ cursor: enableLeftButton ? "pointer" : "default" }}
        onClick={() => HandleClickedNavigateLeft()}
      >
        <img
          src={
            enableLeftButton
              ? GetAffiliate() == affiliates.klm
                ? klmChevronLeft
                : transaviaChevronLeft
              : chevronLeftDisabled
          }
          alt="<"
        />
      </div>
      {monthsToShow.map((monthOption, index) => {
        return (
          <div
            key={index}
            className={`price-matrix-header-month-button ${
              monthOption.selected ? "month-button-selected" : ""
            } ${isLoading ? "month-button-disabled" : ""}`}
            onClick={() => HandleMonthClicked(monthOption)}
          >
            {GetDisplayMonthName(monthOption.displayDate)}
            <br />
            {GetDisplayYearName(monthOption.displayDate)}
          </div>
        );
      })}
      <div
        className="price-matrix-header-month-navigation-button"
        style={{ cursor: enableRightButton ? "pointer" : "default" }}
        onClick={() => HandleClickedNavigateRight()}
      >
        <img
          src={
            enableRightButton
              ? GetAffiliate() == affiliates.klm
                ? klmChevronRight
                : transaviaChevronRight
              : chevronRightDisabled
          }
          className={`${
            GetAffiliate() === affiliates.klm
              ? "klm-price-matrix-navigation-button"
              : "transavia-price-matrix-navigation-button"
          }`}
          alt=">"
        />
      </div>
    </div>
  );
};
