import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, {
  createRef,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useDispatch as useReduxDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { contentType } from "../../common/constants/contentType";
import { Cookies } from "../../common/constants/cookies";
import { affiliates } from "../../common/enums/affiliates";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { holidaySteps } from "../../common/enums/holidaySteps";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";
import { AccommodationComponent } from "../../components/accommodation-component/accommodation-component.component";
import { HolidayPackageFlightComponent } from "../../components/holiday-package-flight/holiday-package-flight.component";
import { HolidayPackageOptionsHotelImagesComponent } from "../../components/holiday-package-hotel-images/holiday-package-hotel-images.component";
import { HolidayPackageOptionsSummaryComponent } from "../../components/holiday-package-options-summary/holiday-package-options-summary.component";
import { HolidayPackageTripComponent } from "../../components/holiday-package-trip/holiday-package-trip.component";
import { HolidaySummaryOverviewComponent } from "../../components/holiday-summary-overview/holiday-summary-overview.component";
import { HotelInformationComponent } from "../../components/hotel-information/hotel-information.component";
import HotelMapComponent from "../../components/hotel-map/hotel-map.component";
import { MealTypeFilter } from "../../components/meal-type-filter/meal-type-filter.component";
import { StaticMapComponent } from "../../components/static-map/static-map.component";
import StepperBarComponent from "../../components/stepper-bar/stepper-bar.component";
import { waitingModalMessageTypes } from "../../components/waiting-modal/waiting-modal-message-service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { getContentfulByContentType } from "../../services/contentful.service";
import {
  GetApiUrl,
  GetDateOnYearFromNow,
} from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { SessionStorageService } from "../../services/session-storage.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import { setHolidayKey } from "../../store/holiday/slice";
import { setHolidayHeader } from "../../store/holidayHeader/slice";
import { checkAvailabilityHelper } from "./check-availability/check-availability.helper";
import "./holiday.component.scss";

const initialState = {
  contentfulButtons: null,
  contentfulFilters: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.HOLIDAY_PAGE:
      return { ...state, contentfulHolidayPage: action.payload };
    case contentfulEntriesReducer.HOLIDAY_STEPPER:
      return { ...state, contentfulHolidayStepper: action.payload };
    case contentfulEntriesReducer.BUTTONS:
      return { ...state, contentfulButtons: action.payload };
    case contentfulEntriesReducer.ROOM_ALLOCATION:
      return { ...state, contentfulRoomAllocation: action.payload };
    case contentfulEntriesReducer.HOLIDAY_PAGE_SUMMARY:
      return { ...state, contentfulHolidayPageSummary: action.payload };
    case contentfulEntriesReducer.HOLIDAY_PAGE_PRICE_MATRIX:
      return { ...state, contentfulHolidayPagePriceMatrix: action.payload };
    case contentfulEntriesReducer.PRICE_CHANGE_MESSAGE:
      return { ...state, contentfulPriceChangeMessage: action.payload };
    case contentfulEntriesReducer.FILTERS:
      return { ...state, contentfulFilters: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const HolidayPageComponent = ({
  queryParameters,
  culture,
  isWaitingForNetwork,
  setDeepLinkParams,
  showCustomModal,
  hideCustomMobile,
  showWaitingScreen,
  hideWaitingScreen,
}) => {
  const stepRef = {
    hotelInformation: useRef(null),
    travel: useRef(null),
    flight: useRef(null),
    mealType: useRef(null),
    accommodation: useRef(null),
    summary: useRef(null),
    notExistingSection: useRef(null),
  };
  const searchBarRef = createRef();
  const [hotelData, setHotelData] = useState([]);
  const [stepperClick, setStepperClick] = useState(null);
  const [detailedHoliday, setDetailedHoliday] = useState(null);
  const contentfulEntries = useContext(ContentfulContext);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);
  const reduxDispatch = useReduxDispatch();
  const [searchInfo, setSearchInfo] = useState(null);
  const [isTaskPending, setIsTaskPending] = useState(false);
  const [isHolidayPending, setIsHolidayPending] = useState(false);
  const [isRoomUpdating, setIsRoomUpdating] = useState(false);
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies();
  const [showStaticMap, setShowStaticMap] = useState(true);

  const holidayKeyRef = useRef(holidayKey);

  const isAccommodationDataLoaded =
    detailedHoliday?.roomOptions && detailedHoliday?.roomOptions.length > 0;
  const isFlightDataLoaded =
    detailedHoliday?.flightOptionsInfo?.flightOptions &&
    detailedHoliday?.flightOptionsInfo?.flightOptions.length > 0;
  const isSummaryDataLoaded =
    detailedHoliday && Object.keys(detailedHoliday).length > 0;

  const areAllComponentsLoaded =
    isAccommodationDataLoaded && isFlightDataLoaded && isSummaryDataLoaded;

  const [
    {
      contentfulHolidayPage,
      contentfulHolidayStepper,
      contentfulButtons,
      contentfulRoomAllocation,
      contentfulHolidayPageSummary,
      contentfulHolidayPagePriceMatrix,
      contentfulFilters
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const contentfulHolidayPageResponse = () => {
    const contentfulHolidayPage = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE
    );
    const contentfulHolidayStepper = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_STEPPER
    );
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );
    const contentfulRoomAllocation = getContentfulByContentType(
      contentfulEntries,
      contentType.ROOM_ALLOCATION
    );
    const contentfulHolidayPageSummary = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE_SUMMARY
    );
    const contentfulHolidayPagePriceMatrix = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE_PRICE_MATRIX
    );
    const contentfulPriceChangeMessage = getContentfulByContentType(
      contentfulEntries,
      contentType.PRICE_CHANGE_MESSAGE
    );
    const contentfulFilters = getContentfulByContentType(
      contentfulEntries,
      contentType.FILTERS
    );

    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_PAGE,
      payload: contentfulHolidayPage,
    });
    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_STEPPER,
      payload: contentfulHolidayStepper,
    });
    dispatch({
      type: contentfulEntriesReducer.BUTTONS,
      payload: contentfulButtons,
    });
    dispatch({
      type: contentfulEntriesReducer.ROOM_ALLOCATION,
      payload: contentfulRoomAllocation,
    });
    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_PAGE_SUMMARY,
      payload: contentfulHolidayPageSummary,
    });
    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_PAGE_PRICE_MATRIX,
      payload: contentfulHolidayPagePriceMatrix,
    });
    dispatch({
      type: contentfulEntriesReducer.PRICE_CHANGE_MESSAGE,
      payload: contentfulPriceChangeMessage,
    });
    dispatch({
      type: contentfulEntriesReducer.FILTERS,
      payload: contentfulFilters,
    });
  };

  useEffect(() => {
    localStorage.removeItem(LocalStorageEnum.CABIN_SELECTION_STATE);
  }, []);

  useEffect(() => {
    contentfulHolidayPageResponse();
  }, [contentfulEntries]);

  useEffect(() => {
    const getHotelData = async () => {
      const parsedParameters = remapSearchParameters();
      if (parsedParameters && Object.keys(parsedParameters).length > 0) {
        if (checkForMandatoryParameters(parsedParameters)) {
          await performGetHotelData(parsedParameters);
        }
      }
    };

    if (culture && !isWaitingForNetwork) {
      getHotelData();
    }
  }, [queryParameters, culture]);

  useEffect(() => {
    const getHotelDetailedHoliday = async () => {
      if (
        hotelData?.data?.holidayKey &&
        hotelData?.data?.holidayKey !== holidayKeyRef.current
      ) {
        reduxDispatch(setHolidayKey(hotelData?.data?.holidayKey));
        holidayKeyRef.current = hotelData?.data?.holidayKey;
        updateHolidayDetails();
      }
    };

    getHotelDetailedHoliday();
  }, [hotelData?.data?.holidayKey]);

  const updateHolidayDetails = async () => {
    setIsHolidayPending(true);
    try {
      const detailedHolidayResponse = await performDetailedHolidayHttpRequest();
      if (detailedHolidayResponse.status === 200) {
        setDetailedHoliday(detailedHolidayResponse.data);
      } else {
        console.error(
          "Unexpected response status:",
          detailedHolidayResponse.status
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching detailed holiday:",
        error
      );
    } finally {
      setIsHolidayPending(false);
    }
  };

  const performRoomOptionSelection = async (roomOptionKey) => {
    try {
      setIsRoomUpdating(true);
      const detailedHolidayResponse = await axios.put(
        `${GetApiUrl()}/Holiday/UpdateRoomOption`,
        {
          holidayKey: holidayKey ?? hotelData.data.holidayKey,
          roomOptionKey: roomOptionKey,
        }
      );
      setDetailedHoliday(detailedHolidayResponse.data);
      setHotelData(detailedHolidayResponse);
      scrollToStep(
        { key: holidaySteps.ACCOMMODATION, toggleStepClicked: true },
        "start"
      );
    } catch (error) {
      console.error("Error when downloading room data::", error);
    } finally {
      setIsRoomUpdating(false);
    }
  };

  useEffect(() => {
    if (hotelData?.data?.holidayHeader) {
      reduxDispatch(setHolidayHeader(hotelData.data.holidayHeader));
    }
  }, [hotelData?.data?.holidayHeader, reduxDispatch]);

  const performGetHotelData = async (searchDto) => {
    setIsTaskPending(true);
    setHotelData([]);
    if (searchDto && !isTaskPending && searchParams) {
      try {
        const hotelDataResponse = await axios.post(
          `${GetApiUrl()}/Holiday?clientKey=${
            process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
          }`,
          {
            ...searchDto,
            holidayKey: holidayKey,
            allInclusive:
              JSON.parse(searchParams.get(searchParamsEnum.allInclusive)) ??
              false,
            authInfo: {
              authKey: cookies?.authKey ?? "",
              sessionId: SessionStorageService.getSessionId() ?? "",
            },
          }
        );
        if (!cookies?.authKey) {
          setCookie(
            Cookies.AuthKey,
            hotelDataResponse?.data?.authInfo?.authKey,
            {
              expires: GetDateOnYearFromNow(),
            }
          );
        }
        if (!SessionStorageService.getSessionId()) {
          SessionStorageService.setSessionId(
            hotelDataResponse?.data?.authInfo?.sessionId
          );
        }
        setHotelData(hotelDataResponse);
        setDeepLinkParams(hotelDataResponse?.data?.holidayHeader?.navigateToHolidayList);
        const searchInfoResponse = hotelDataResponse?.data.holidayAlternatives?.searchBarInfo;
        const searchInfoResponseParsed = remapSearchBarInfo(searchInfoResponse);
        setSearchInfo(searchInfoResponseParsed);
        LocalStorageService.setLocalStorageItem(LocalStorageEnum.FLIGHT_ORIGIN_IATA_CODE, hotelDataResponse?.data?.holidayHeader?.departureAirport);
        LocalStorageService.setLocalStorageItem(LocalStorageEnum.FLIGHT_DESTINATION_IATA_CODE, hotelDataResponse?.data?.holidayHeader?.returnAirport);
        analyticsHandlePageViewEvent(searchDto, hotelDataResponse);
      } catch (error) {
        console.error("Error when downloading hotel data:", error);
      } finally {
        setIsTaskPending(false);
      }
    }
  };

  useEffect(() => {
    if (isTaskPending) {
      performGetHotelData();
    }
  }, [isTaskPending]);

  const remapSearchBarInfo = (searchBarInfo) => {
    return {
      departureLocations: searchBarInfo?.departureLocations,
      destinationLocationCode: searchBarInfo?.destinationLocationCode,
      roomAllocations: searchBarInfo?.roomAllocations,
      searchDates: {
        departureDate: searchBarInfo?.departureDate,
        departureDateFlexibility: searchBarInfo?.departureDateFlexibility,
        displayDepartureDate: searchBarInfo?.displayDepartureDate,
        displayReturnDate: searchBarInfo?.displayReturnDate,
        returnDate: searchBarInfo?.returnDate,
      },
    };
  };

  const remapSearchParameters = () => {
    const searchDto = {
      departureLocationCode: queryParameters.departurelocation,
      destinationLocationCode: queryParameters.destinationlocation,
      culture: culture,
      hotelId: queryParameters.hotelid,
      departureDate: queryParameters.departuredate,
      returnDate: queryParameters.returndate,
      roomAllocationCode: queryParameters.roomallocation,
      holidayKey: holidayKey,
    };
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.HOTEL_ID,
      queryParameters.hotelid
    );

    if (checkForMandatoryParameters(searchDto)) {
      return searchDto;
    }
  };

  const checkForMandatoryParameters = (searchDto) => {
    if (
      searchDto?.departureLocationCode &&
      searchDto?.destinationLocationCode &&
      searchDto?.culture &&
      searchDto?.hotelId &&
      searchDto?.departureDate &&
      searchDto?.returnDate
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    scrollToStep(stepperClick);
  }, [stepperClick]);

  const scrollToStep = (step, scrollToBlock = "nearest") => {
    stepRef[step?.key]?.current?.scrollIntoView({
      behavior: "smooth",
      block: scrollToBlock,
    });

    setTimeout(() => {
      stepRef["notExistingSection"]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 100);
  };

  const performSelectAlternativeOption = async (
    selectAlternativeOptionRequestBody
  ) => {
    setIsTaskPending(true);
    try {
      const requestUrl = `${GetApiUrl()}/Holiday/UpdateHolidayOption?clientKey=${
        process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`;
      const selectAlternativeOptionResponse = await axios.put(
        requestUrl,
        selectAlternativeOptionRequestBody
      );

      setHotelData(selectAlternativeOptionResponse);
      setDeepLinkParams(
        selectAlternativeOptionResponse?.data?.holidayHeader
          ?.navigateToHolidayList
      );
      updateHolidayDetails();
    } catch (error) {
      console.error("Error in choosing an alternative option:", error);
    } finally {
      setIsTaskPending(false);
    }
  };

  const performCheckAvailability = async (checkAvailabilityRequestBody) => {
    setIsTaskPending(true);
    showWaitingScreen(waitingModalMessageTypes.checkingPrices);
    try {
      const requestUrl = `${GetApiUrl()}/Holiday/ValidateHoliday?clientKey=${
        process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`;
      const checkAvailabilityResponse = await axios.post(
        requestUrl,
        checkAvailabilityRequestBody
      );
      const contentfulCheckAvailability =
        contentfulHolidayPage &&
        contentfulHolidayPage[0]?.fields?.checkAvailability
          ? contentfulHolidayPage[0]?.fields?.checkAvailability
          : null;
      checkAvailabilityHelper.processCheckAvailabilityResponse(
        checkAvailabilityResponse,
        contentfulCheckAvailability,
        showCustomModal,
        hideCustomMobile
      );
    } catch (error) {
      console.error("Error when checking availability:", error);
    } finally {
      hideWaitingScreen();
      setIsTaskPending(false);
      analyticsHandleCheckAvailability(checkAvailabilityRequestBody);
    }
  };

  const performDetailedHolidayHttpRequest = async () => {
    return axios.post(
      `${GetApiUrl()}/Holiday/DetailedHoliday?clientKey=${
        process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`,
      {
        holidayKey: hotelData.data.holidayKey,
        cultureCode: culture ?? LocalStorageService.getCulture(),
      }
    );
  };

  const performUpdateFlightOption = async (updateFlightOptionRequestBody) => {
    setIsTaskPending(true);
    try {
      const requestUrl = `${GetApiUrl()}/Holiday/UpdateFlightOption?clientKey=${
        process.env?.REACT_APP_AFFILIATE ?? affiliates.klm
      }`;
      const updateFlightOptionResponse = await axios.put(
        requestUrl,
        updateFlightOptionRequestBody
      );
      setHotelData(updateFlightOptionResponse);
      reduxDispatch(setHolidayKey(updateFlightOptionResponse.data.holidayKey));
      setDetailedHoliday(updateFlightOptionResponse.data);
      scrollToStep(
        { key: holidaySteps.FLIGHT, toggleStepClicked: true },
        "start"
      );
    } catch (error) {
      console.error("Error when updating flight options:", error);
    } finally {
      setIsTaskPending(false);
      analyticsHandleUpdateFlightOption(updateFlightOptionRequestBody);
    }
  };

  const analyticsHandlePageViewEvent = (searchDto, hotelDataResponse) => {
    AnalyticsService.handleEvents([
      {
        platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
        type: AnalyticsService.analyticsConstants.eventType.pageView,
        name: AnalyticsService.analyticsConstants.apiEvents.holiday,
        data: hotelDataResponse?.data?.holidayKey,
      },
      {
        platform: AnalyticsService.analyticsConstants.platform.gtag,
        type: AnalyticsService.analyticsConstants.eventType.pageView,
        data: searchDto
      },
    ]);
  };

  const analyticsHandleCheckAvailability = async (
    checkAvailabilityRequestBody
  ) => {
    AnalyticsService.handleEvents([
      {
        platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
        type: AnalyticsService.analyticsConstants.eventType.customEvent,
        name: AnalyticsService.analyticsConstants.apiEvents.checkAvailability,
        data: checkAvailabilityRequestBody.holidayKey,
      },
    ]);
  };

  const analyticsHandleUpdateFlightOption = async (
    updateFlightOptionRequestBody
  ) => {
    AnalyticsService.handleEvents([
      {
        platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
        type: AnalyticsService.analyticsConstants.eventType.customEvent,
        name: AnalyticsService.analyticsConstants.apiEvents.changeFlight,
        data: updateFlightOptionRequestBody.holidayKey,
      },
    ]);
  };

  const onMapClickChange = () => {
    setShowStaticMap(!showStaticMap);
  };

  return (
    <div className="holiday-page-component">
      <HolidayPackageOptionsSummaryComponent
        holidayHeader={hotelData?.data?.holidayHeader}
        contentfulHolidayPage={contentfulHolidayPage}
        searchBarRef={searchBarRef}
      />

      <HolidayPackageOptionsHotelImagesComponent
        hotelInfo={hotelData?.data?.hotelInfo}
        contentfulHolidayPage={contentfulHolidayPage}
      />

      <div className="hotel-information-section-wrapper">
        <section
          className="hotel-information-section"
          ref={stepRef.hotelInformation}
        >
          <StepperBarComponent
            contentfulHolidayStepper={contentfulHolidayStepper}
            setStepperClick={setStepperClick}
          />
          <div className="hotel-information-title">
            {contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.hotelInformation}
          </div>

          <div className="hotel-information-description">
            <HotelInformationComponent
              hotelData={hotelData}
              contentfulHolidayPage={contentfulHolidayPage}
              contentfulButtons={contentfulButtons}
              searchBarRef={searchBarRef}
              detailedHolidayData={detailedHoliday}
            />

            <div>
              <span className="location-map-description">
                {contentfulHolidayPage &&
                  contentfulHolidayPage[0]?.fields?.location}
              </span>
              <div>
                {showStaticMap ? (
                  <div onClick={onMapClickChange}>
                    <StaticMapComponent
                      hotelPosition={
                        hotelData.data?.hotelInfo?.location?.position
                      }
                    />
                  </div>
                ) : (
                  <HotelMapComponent
                    contentfulHolidayPage={contentfulHolidayPage}
                    hotelPosition={
                      hotelData.data?.hotelInfo?.location?.position
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <section
        ref={stepRef.travel}
        className={areAllComponentsLoaded ? "" : "loading"}
      >
        <HolidayPackageTripComponent
          searchInfo={hotelData?.data?.holidayAlternatives?.searchBarInfo}
          performSearch={performGetHotelData}
          hotelDataResponse={hotelData}
          holidayAlternatives={hotelData?.data?.holidayAlternatives}
          contentfulHolidayPage={contentfulHolidayPage}
          contentfulHolidayPriceMatrix={contentfulHolidayPagePriceMatrix}
          performSelectAlternativeOption={performSelectAlternativeOption}
          searchBarRef={searchBarRef}
          holidayHeader={hotelData?.data?.holidayAlternatives}
        />
        {isTaskPending || isHolidayPending || !areAllComponentsLoaded ? (
          <div className="loading-wrapper">
            <section className="spinner-container">
              <CircularProgress />
              <p className="spinner-text">
                {contentfulHolidayPage &&
                  contentfulHolidayPage[0]?.fields?.spinnerText}
              </p>
            </section>
          </div>
        ) : (
          <>
            <section ref={stepRef.flight}>
              <HolidayPackageFlightComponent
                contentfulHolidayPage={contentfulHolidayPage}
                contentfulButtons={contentfulButtons}
                contentfulFilters={contentfulFilters}
                holidayKey={hotelData?.data?.holidayKey}
                holidayHeader={hotelData?.data?.holidayHeader}
                holidayAirlineName={
                  detailedHoliday?.holidayDetailInfo.flightInfo
                }
                flightOptions={
                  detailedHoliday?.flightOptionsInfo?.flightOptions
                }
                performUpdateFlightOption={performUpdateFlightOption}
                setStepperClick={setStepperClick}
                flightOptionsFilterInfo={detailedHoliday?.flightOptionsInfo?.filterInfo}
              />
            </section>

            <section ref={stepRef.mealType}>
              <MealTypeFilter
                filters={detailedHoliday?.roomOptionInfo?.filterInfo?.filters}
                contentfulButtons={contentfulButtons}
                contentfulAccommodationSection={
                  contentfulHolidayPage &&
                  contentfulHolidayPage[0]?.fields?.accommodationSection
                }
                performRoomOptionSelection={performRoomOptionSelection}
                isLoading={isRoomUpdating}
              />
            </section>
            <section ref={stepRef.accommodation}>
              <AccommodationComponent
                contentfulButtons={contentfulButtons}
                contentfulAccommodationSection={
                  contentfulHolidayPage &&
                  contentfulHolidayPage[0]?.fields?.accommodationSection
                }
                accommodationRoomOptionsInfo={detailedHoliday?.roomOptionInfo}
                contentfulAccommodationShowDetails={
                  contentfulHolidayPage &&
                  contentfulHolidayPage[0]?.fields?.showAllRoomDetails
                }
                authInfo={detailedHoliday?.authInfo?.sessionType}
                performRoomOptionSelection={performRoomOptionSelection}
                isLoading={isRoomUpdating}
              />
            </section>

            <section ref={stepRef.summary} className="summary-section">
              <HolidaySummaryOverviewComponent
                contentfulHolidayPageSummary={contentfulHolidayPageSummary}
                contentfulHolidayPage={contentfulHolidayPage}
                contentfulButtons={contentfulButtons}
                contentfulRoomAllocation={contentfulRoomAllocation}
                detailedHoliday={detailedHoliday}
                performCheckAvailability={performCheckAvailability}
              />
            </section>
          </>
        )}
      </section>
    </div>
  );
};
