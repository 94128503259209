import axios from "axios";
import { GetApiUrl } from "../general.service";
import { LocalStorageService } from "../local-storage.service";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";

export const InitiatePayment = async (checkoutId) => {
  const response = await axios.post(
    `${GetApiUrl()}/Checkout/${checkoutId}/payment`,
    null
  );
  return response;
};

export const GetCosts = async (checkoutId, encryptedCardNumber) => {
  const response = await axios.put(
    `${GetApiUrl()}/Checkout/${checkoutId}/PaymentCalculateCost`,
    { EncryptedCreditCardNumber: encryptedCardNumber }
  );
  return response;
};

export const SelectPaymentMethod = async (checkoutId, paymentDetailsDTO) => {
  try {
    const response = await axios.put(
      `${GetApiUrl()}/Checkout/${checkoutId}/SelectPayment`,
      paymentDetailsDTO
    );
        
    return response;
  } catch (error) {
    console.error(
      error.response?.data ? JSON.stringify(error.response.data, null, 2) : error.message
    );
    throw error;
  }
};

export const MakePayment = async (checkoutId) => {
  let returnUrl = window.location.href;
  if (returnUrl.charAt(returnUrl.length - 1) === "/") {
    returnUrl = returnUrl.substring(0, returnUrl.length - 1);
  }
  returnUrl = returnUrl.substring(0, returnUrl.lastIndexOf("/"));
  returnUrl += "/payment";

  const payload = {
    returnUrl: process.env.REACT_APP_IS_DEV ? returnUrl : "",
  };

  try {
    const response = await axios.post(
      `${GetApiUrl()}/PaymentConfirmation/${checkoutId}`,
      payload
    );

    return response;
  } catch (error) {
    console.error(error.response?.data || error.message);
    throw error;
  }
};

export const FinalisePayment = async (redirectResult, checkoutId) => {
  const response = await axios.put(
    `${GetApiUrl()}/PaymentConfirmation/${checkoutId}/redirectresult`,
    {
      redirectResult: redirectResult,
    }
  );
  return response;
};
