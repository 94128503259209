import React, { useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { GetMealType, mealTypes } from "../../common/enums/meal-types";
import { affiliates } from "../../common/enums/affiliates";
import { ReactComponent as SelectedIconKLM } from "../../assets/icons/check-round-S.klm.svg";
import { ReactComponent as RoomOnlyIconKLM } from "../../assets/icons/klm/KLM_RoomOnly.svg";
import { ReactComponent as BnbIconKLM } from "../../assets/icons/klm/KLM_Bed&Breakfast.svg";
import { ReactComponent as MealIconKLM } from "../../assets/icons/klm/KLM_Meal.svg";
import { ReactComponent as AllInclusiveIconKLM } from "../../assets/icons/klm/klm-allInclusive.svg";
import { ReactComponent as RoomOnlyIconTransavia } from "../../assets/icons/transavia/transavia_RoomOnly.svg";
import { ReactComponent as BnbIconTransavia } from "../../assets/icons/transavia/transavia_Bed&Breakfast.svg";
import { ReactComponent as MealIconTransavia } from "../../assets/icons/transavia/transavia_food.svg";
import { ReactComponent as AllInclusiveIconTransavia } from "../../assets/icons/transavia/transavia_AllInclusive.svg";
import "./meal-type-filter.component.scss";
import { CircularProgress } from "@mui/material";

export const MealTypeFilter = ({
  filters,
  contentfulButtons,
  contentfulAccommodationSection,
  performRoomOptionSelection,
  isLoading,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState();

  const buildMealTypeFilterClasses = (filter) => {
    let classes = ["meal-type-filter-option"];

    if (filter.selected) {
      classes.push("option-selected");
    }

    return classes.join(" ");
  };

  const getMealTypeIcon = (code) => {
    const mealTypeCodes = mealTypes[0].codes;
    const roomOnlyCodes = [mealTypeCodes.RO, mealTypeCodes.SC];
    const foodIncludedCodes = [
      mealTypeCodes.DO,
      mealTypeCodes.FB,
      mealTypeCodes.FBP,
      mealTypeCodes.HB,
      mealTypeCodes.HBP,
      mealTypeCodes.LO,
    ];
    const allInclusiveCodes = [
      mealTypeCodes.AI,
      mealTypeCodes.AIL,
      mealTypeCodes.AIP,
      mealTypeCodes.AIU,
    ];
    const bedAndBreakfastCodes = [mealTypeCodes.BB];
    let IconComponent;

    if (roomOnlyCodes.includes(code)) {
      IconComponent =
        GetAffiliate() === affiliates.klm
          ? RoomOnlyIconKLM
          : RoomOnlyIconTransavia;
    } else if (foodIncludedCodes.includes(code)) {
      IconComponent =
        GetAffiliate() === affiliates.klm ? MealIconKLM : MealIconTransavia;
    } else if (allInclusiveCodes.includes(code)) {
      IconComponent =
        GetAffiliate() === affiliates.klm
          ? AllInclusiveIconKLM
          : AllInclusiveIconTransavia;
    } else if (bedAndBreakfastCodes.includes(code)) {
      IconComponent =
        GetAffiliate() === affiliates.klm ? BnbIconKLM : BnbIconTransavia;
    }

    return IconComponent ? (
      <IconComponent className="type-info__type-icon" alt="Meal type icon" />
    ) : null;
  };

  const selectMealTypeOption = async (index, optionKey) => {
    setSelectedOptionIndex(index);
    await performRoomOptionSelection(optionKey);
  };

  return (
    <div className="meal-type-filter">
      <div className="section-header">
        {contentfulAccommodationSection &&
          contentfulAccommodationSection.mealTypeHeader}
      </div>
      <div className="meal-type-filter__meal-types">
        {filters &&
          filters.map(
            (filter, index) =>
              filter.roomOptionKey && (
                <div
                  key={`meal-type-filter-${index}`}
                  className={buildMealTypeFilterClasses(filter)}
                >
                  <div className="meal-type-filter-option__type-info">
                    <div className="type-info__wrapper">
                      {getMealTypeIcon(filter.mealTypeCode)}
                      <span className="type-info__name">
                        {GetMealType(filter.mealTypeCode)}
                      </span>
                    </div>
                    {!filter.selected && (
                      <div className="type-info__additional-price">
                        {(Number(filter.price) > 0 || !filter.price) && (
                          <span className="additional-price__sign">+</span>
                        )}
                        {Number(filter.price) < 0 && (
                          <span className="additional-price__sign">-</span>
                        )}
                        {` € ${
                          filter.price
                            ? Number(filter.price) > 0
                              ? filter.price
                              : filter.price.replace("-", "")
                            : 0
                        }`}
                        {
                          <span className="additional-price__per-person">
                            {" "}
                            {contentfulAccommodationSection &&
                              contentfulAccommodationSection.pricePerPerson}
                          </span>
                        }
                      </div>
                    )}
                  </div>

                  {filter.selected ? (
                    <div className="meal-type-filter-option__selected-indicator">
                      <SelectedIconKLM className="selected-indicator__icon" />
                      {contentfulButtons &&
                        contentfulButtons[0]?.fields?.selected}
                    </div>
                  ) : (
                    <button
                      className="select__button"
                      onClick={() =>
                        selectMealTypeOption(index, filter.roomOptionKey)
                      }
                      disabled={isLoading && index === selectedOptionIndex}
                    >
                      {isLoading && index === selectedOptionIndex ? (
                        <CircularProgress
                          className="button__loading-spinner"
                          size={4}
                        />
                      ) : null}
                      {contentfulButtons &&
                      isLoading &&
                      index === selectedOptionIndex
                        ? contentfulButtons[0]?.fields?.loadingState
                        : contentfulButtons[0]?.fields?.select}
                    </button>
                  )}
                </div>
              )
          )}
      </div>
    </div>
  );
};
