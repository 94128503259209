import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { ShoppingCartTooltipEnums } from "../../common/enums/shoppingCartTooltipEnums";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import Tooltip from "../tooltip/tooltip";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { PagesConstant } from "../../common/constants/pages";
import { PriceWarranty } from "../price-warranty/price-warranty";
import { flightAirlineOperator } from "../../common/enums/flightOptions";
import { isDateDifferenceHigher } from "../../services/date-formatter.service";

export const PriceOverviewTotal = ({
  payNow,
  contentfulHolidayPageSummary,
  contentfulConfirmationPage,
  contentfulPaymentPage,
  newSearch,
  data
}) => {
  const route = window.location.pathname;
  const [tooltipVisible, setTooltipVisible] = useState({});
  const [checkOutData, setCheckOutData] = useState();
  const localFee = checkOutData?.shoppingCart?.priceInfo?.localFee;

  useEffect(() => {
    setCheckOutData(data);
  }, [data]);

  const isPriceWarrantyDateValid = useMemo(() => {
    return isDateDifferenceHigher()
  }, [])

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  const displayTrigger = (value) => {
    const output =
      !isNaN(parseInt(value)) && parseInt(value) !== 0 ? true : false;

    return output;
  };

  return (
    <Fragment>
        { data?.checkOutSummary?.flightDetails?.inboundFlight?.airlineCode === flightAirlineOperator.TRANSAVIA_Flight &&
          data?.checkOutSummary?.flightDetails?.outboundFlight?.airlineCode === flightAirlineOperator.TRANSAVIA_Flight &&
          isPriceWarrantyDateValid && GetAffiliate() === affiliates.transavia &&
        <div className="holiday-card-amenity shopping-cart-price-warranty">
          <PriceWarranty/>
        </div>
      }
      <div className="price-total">
        {(checkOutData?.shoppingCart?.priceInfo?.totalPayment || localFee) &&
          displayTrigger(checkOutData?.shoppingCart?.priceInfo?.totalPayment) && (
            <div className="content-item">
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields?.payNow}
              </div>
              <div className="content-item-price">
                € {checkOutData?.shoppingCart?.priceInfo?.totalPayment}
              </div>
            </div>
          )}

        {/* DOWNPAYMENT PAY LATER */}
        {checkOutData?.paymentInfo?.downPaymentPossible &&
          checkOutData?.paymentInfo?.downPaymentSelected &&
          displayTrigger(checkOutData?.shoppingCart?.priceInfo?.totalFuturePayment) && (
            <>
              <div className="content-item">
                <div className="downpayment-pay-later">
                  {contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.futurePayment}{" "}
                  {checkOutData?.shoppingCart?.priceInfo?.futurePaymentDate}
                  <div
                    className="content-item-icon"
                    onMouseOver={() =>
                      handleMouseOver(ShoppingCartTooltipEnums.RestToPay)
                    }
                    onMouseOut={() =>
                      handleMouseOut(ShoppingCartTooltipEnums.RestToPay)
                    }
                  >
                    <IconInfo className="icon-inform" />
                    {tooltipVisible[ShoppingCartTooltipEnums.RestToPay] && (
                      <Tooltip
                        header={
                          contentfulHolidayPageSummary &&
                          contentfulPaymentPage[0].fields
                            .downPaymentTooltipHeader
                        }
                        body={
                          contentfulHolidayPageSummary &&
                          documentToReactComponents(
                            contentfulPaymentPage[0].fields
                              .downPaymentTooltipBody
                          )
                        }
                      />
                    )}
                  </div>
                </div>{" "}
                <div className="content-item-price">
                  € {checkOutData?.shoppingCart?.priceInfo?.totalFuturePayment}
                </div>
              </div>
            </>
          )}

        {/* LOCAL FEE */}
        {localFee && (
          <div className="content-item">
            <div>
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.localfees}
              <div
                className="content-item-icon"
                onMouseOver={() =>
                  handleMouseOver(ShoppingCartTooltipEnums.LocalFee)
                }
                onMouseOut={() =>
                  handleMouseOut(ShoppingCartTooltipEnums.LocalFee)
                }
              >
                <IconInfo className="icon-inform" />
                {tooltipVisible[ShoppingCartTooltipEnums.LocalFee] && (
                  <Tooltip
                    header={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.betalen
                    }
                    body={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.plaatseContent
                    }
                  />
                )}
              </div>
            </div>{" "}
            <div className="content-item-price">€ {localFee}</div>
          </div>
        )}

        {/* TOTAL PRICE */}
        <div className="content-item total">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.total}
          </div>{" "}
          <div className="content-item-price-total">
            € {checkOutData?.shoppingCart?.priceInfo?.total}
          </div>
        </div>

        {/* DOWNPAYMENT OPTION */}
        {checkOutData?.paymentInfo?.downPaymentPossible &&
          !checkOutData?.paymentInfo?.downPaymentSelected &&
          displayTrigger(checkOutData?.shoppingCart?.priceInfo?.downPayment) && (
            <div className="content-item">
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields?.betalenHeaderFee}
                <div
                  className="content-item-icon"
                  onMouseOver={() =>
                    handleMouseOver(ShoppingCartTooltipEnums.Downpayment)
                  }
                  onMouseOut={() =>
                    handleMouseOut(ShoppingCartTooltipEnums.Downpayment)
                  }
                >
                  <IconInfo className="icon-inform" />
                  {tooltipVisible[ShoppingCartTooltipEnums.Downpayment] && (
                    <Tooltip
                      header={
                        contentfulPaymentPage &&
                        contentfulPaymentPage[0].fields.downPaymentTooltipHeader
                      }
                      body={
                        contentfulPaymentPage &&
                        documentToReactComponents(
                          contentfulPaymentPage[0].fields.downPaymentTooltipBody
                        )
                      }
                    />
                  )}
                </div>
              </div>{" "}
              <div className="content-item-price">
                € {checkOutData?.paymentInfo?.downPaymentAmount}
              </div>
            </div>
          )}

        {route.startsWith(`${PagesConstant.BASEPATH}/${PagesConstant.CONFIRMATION}`) && (
          <button
            className="new-search"
            onClick={() => {
              if (GetAffiliate() === affiliates.klm) {
                window.location.href =
                  PagesConstant.NEW_SEARCH_KLM_HOLIDAYS_URL;
              } else {
                window.location.href =
                  PagesConstant.NEW_SEARCH_TRANSAVIA_HOLIDAYS_URL;
              }
            }}
          >
            {contentfulConfirmationPage &&
              contentfulConfirmationPage[0]?.fields?.newSearch}
          </button>
        )}
      </div>
    </Fragment>
  );
};
