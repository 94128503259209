import React, { useState, useRef, useEffect, useCallback } from "react";
import "./dropdown.scss";

export const DropdownComponent = ({
  children,
  hasOwnInput,
  defaultPlaceholder,
  useFormStyling,
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const containerRef = useRef(null);

  const closeDropdown = useCallback(() => {
    setDropdownIsOpen(false);
  }, []);

  const handleOutsideClick = useCallback(
    (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        closeDropdown();
      }
    },
    [containerRef, closeDropdown]
  );

  const toggleDropdown = useCallback(() => {
    setDropdownIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick]);

  return (
    <div className="dropdown-component">
      <div
        ref={containerRef}
        className={`dropdown-container ${dropdownIsOpen ? "dropdown-active" : ""}`}
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
      >
        <span
          className={`dropdown-text ${
            useFormStyling ? "dropdown-text-form-styling" : ""
          }`}
        >
          {hasOwnInput ? children : (
            <span className={dropdownIsOpen ? "placeholder-bold" : ""}>
              {defaultPlaceholder}
            </span>
          )}
        </span>

        {!hasOwnInput && (
          <div className="dropdown-caret">
            {dropdownIsOpen ? <span className="rotate"></span> : null}
          </div>
        )}

        {dropdownIsOpen && !hasOwnInput && (
          <>
            <div className="dropdown-options">
              {typeof children === "function" ? children(closeDropdown) : children}
            </div>
            <div className="arrow-indicator" />
          </>
        )}
      </div>
    </div>
  );
};
