import React, { useContext, useEffect, useState } from "react";
import "./price-warranty.scss";
import { ReactComponent as SwitchButton } from "../../assets/icons/transavia/switch-button.svg";
import ContentfulContext from "../../store/contentful/contentful-context";
import { contentType } from "../../common/constants/contentType";
import { getContentfulByContentType } from "../../services/contentful.service";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const PriceWarranty = () => {
  const contentfulEntries = useContext(ContentfulContext);
  const [contentfulGeneral, setStateContentfulGeneral] = useState("");

  useEffect(() => {
    const getContentfulGeneral = getContentfulByContentType(
      contentfulEntries,
      contentType.GENERAL
    );
    setStateContentfulGeneral(getContentfulGeneral);
  }, [contentfulEntries]);

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
            <OpenInNewIcon className="open-new-tab-icon" />
        </a>
      ),
    },
  };

  return (
    <div className="price-warranty-container">
      <SwitchButton />
      {documentToReactComponents(contentfulGeneral &&
        contentfulGeneral[0]?.fields?.priceWarranty,
        options
      )}
    </div>
  );
};
