import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useState } from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Alert } from "@mui/material";
import { CabinBaggageCard } from "./cabin-baggage-card.component";
import "./cabin-luggage.component.scss";
import { HandBaggageCard } from "./hand-baggage-card.component";
import { BaggageComponent } from "../baggage/baggage.component";
import CabinTripComponent from "./cabin-trip-baggage";

export const CabinLuggage = ({
  contentfulLuggageSelection,
  baggageDetails,
  setIsWaitingForNetwork,
  isWaitingForNetwork,
  contentfulCheckoutPassengerDetails,
  onBaggageSelectionChanged,
  onCabinBaggageSelectionChanged,
  contentfulExtrasPage,
  isLoading,
  loadingPassengerId,
  isBaggageValid,
  isValidationInProgress,
  onValidate,
}) => {
  const [cabinBaggageOptions, setCabinBaggageOptions] = useState();
  const [isCabinBaggageSelected, setIsCabinBaggageSelected] = useState(false);
  const [showCabinBaggageInfo, setShowCabinBaggageInfo] = useState(false);
  const [showBaggageOptions, setShowBaggageOptions] = useState(false);
  const [isCabinBaggageSoldOut, setIsCabinBaggageSoldOut] = useState(false);
  const [cabinSelectionState, setCabinSelectionState] = useState({
    noCabinSelected: false,
    cabinSelected: false,
  });
  const [errorState, setErrorState] = useState({
    nothingSelected: true,
    noCabinPerPassengerSelected: false,
  });

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="cabin-luggage-description-paragraph">{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  const validateCabinLuggage = () => {
    if (
      showCabinBaggageInfo &
      (errorState.nothingSelected ||
        (errorState.noCabinPerPassengerSelected &&
          !cabinBaggageOptions.find((option) => option.isSelected)))
    ) {
      onValidate(false);
    } else {
      onValidate(true);
    }
  };

  useEffect(() => {
    if (isValidationInProgress) {
      validateCabinLuggage();
    }
  }, [isValidationInProgress]);

  useEffect(() => {
    if (isCabinBaggageSelected) {
      setCabinSelectionState({
        noCabinSelected: false,
        cabinSelected: true,
      });
      setErrorState({ ...errorState, nothingSelected: false });
    }
  }, [isCabinBaggageSelected]);

  useEffect(() => {
    if (baggageDetails) {
      if (baggageDetails.showCabinBaggageInfo) {
        setCabinBaggageOptions(baggageDetails.cabinBaggageProducts);
      }
      setIsCabinBaggageSelected(baggageDetails.showSelectedCabinBaggage);
      setShowCabinBaggageInfo(baggageDetails.showCabinBaggageInfo);
      setShowBaggageOptions(baggageDetails.baggageProducts);
      setIsCabinBaggageSoldOut(
        baggageDetails.showWarningNoAvailableCabinBaggage
      );
    }
  }, [baggageDetails]);

  useEffect(() => {
    if (isCabinBaggageSoldOut) {
      setErrorState({ ...errorState, nothingSelected: false });
    }
  }, [isCabinBaggageSoldOut]);

  return (
    <div className="cabin-luggage-container">
      <div className="cabin-luggage-information">
        {showCabinBaggageInfo && (
          <>
            <Alert className="cabin-luggage-description" severity="info">
              {contentfulLuggageSelection &&
                documentToReactComponents(
                  contentfulLuggageSelection[0]?.fields?.disclaimer,
                  options
                )}
            </Alert>
            <HandBaggageCard luggageData={contentfulLuggageSelection} />
            <CabinBaggageCard
              luggageData={contentfulLuggageSelection}
              baggageDetails={baggageDetails}
              allAncillaryOptionsEmpty={cabinBaggageOptions?.length === 0}
              onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
              cabinSelectionState={cabinSelectionState}
              setCabinSelectionState={setCabinSelectionState}
              errorState={errorState}
              setErrorState={setErrorState}
              isBaggageValid={isBaggageValid}
              onValidate={onValidate}
              isCabinBaggageSoldOut={isCabinBaggageSoldOut}
            />
            <CabinTripComponent
              luggageData={contentfulLuggageSelection}
              baggageDetails={baggageDetails}
              onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
              contentfulExtrasPage={contentfulExtrasPage}
              cabinSelectionState={cabinSelectionState}
              errorState={errorState}
              setErrorState={setErrorState}
              isBaggageValid={isBaggageValid}
              onValidate={onValidate}
            />
          </>
        )}
        {showBaggageOptions?.length > 0 && (
          <BaggageComponent
            setIsWaitingForNetwork={setIsWaitingForNetwork}
            isWaitingForNetwork={isWaitingForNetwork}
            contentfulCheckoutPassengerDetails={
              contentfulCheckoutPassengerDetails
            }
            contentfulExtrasPage={contentfulExtrasPage}
            onBaggageSelectionChanged={onBaggageSelectionChanged}
            baggageDetails={baggageDetails}
            isLoading={isLoading}
            loadingPassengerId={loadingPassengerId}
          />
        )}
      </div>
    </div>
  );
};
