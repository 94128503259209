import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as BackIconKLM } from "../../assets/icons/klm/back_icon_KLM.svg";
import { ReactComponent as BackIconTransavia } from "../../assets/icons/transavia/back_icon_transavia.svg";
import { breakpoints } from "../../common/constants/breakpoints";
import { contentType } from "../../common/constants/contentType";
import { PagesConstant } from "../../common/constants/pages";
import { affiliates } from "../../common/enums/affiliates";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate } from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import { CalamityHeader } from "../calamity-header/calamity-header.component";
import CloseCart from "../close-cart/close-cart";
import { ShoppingCartComponent } from "../shopping-cart/shopping-cart.component";
import SummaryBar from "../summary-bar/summary-bar";
import { LanguageSelectorComponent } from "./language-selector/language-selector.component";

export const HeaderComponent = ({
  queryParameters,
  culture,
  language,
  handleLanguageChange,
  previousCulture,
  isMobile,
  setIsMobile,
  deepLinkParams,
  checkOutData,
  isHolidayPage,
}) => {
  const route = window.location.pathname;
  const [affiliateLogoUrl, setAffiliateLogoUrl] = useState("");
  const [affiliateLogoLink, setAffiliateLogoLink] = useState("");
  const [headerContent, setHeaderContent] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [navigateBackUrl, setNavigateBackUrl] = useState("");
  const windowSizeDetector = useWindowSizeDetector();

  const contentfulEntries = useContext(ContentfulContext);
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);

  useEffect(() => {
    const contentfulHeader = getContentfulByContentType(
      contentfulEntries,
      contentType.HEADER
    );
    setHeaderContent(contentfulHeader);
    setAffiliateLogoUrl(
      "https:" +
        (contentfulHeader && contentfulHeader[0]?.fields?.logo.fields.file.url)
    );
    setAffiliateLogoLink(
      contentfulHeader && contentfulHeader[0]?.fields?.logoLink
    );
  }, [contentfulEntries, culture, previousCulture]);

  useEffect(() => {
    const isTablet =
      windowSizeDetector.width > breakpoints.SM &&
      windowSizeDetector.width < breakpoints.MD;

    setIsTablet(isTablet);

    if (showShoppingCart && isTablet) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [windowSizeDetector.width, showShoppingCart]);

  useEffect(() => {
    if (
      (isMobile || isTablet) &&
      getCleanedRoute() !== `/${PagesConstant.RESULTS}` &&
      getCleanedRoute() !== `/${PagesConstant.HOLIDAY}`
    ) {
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };

      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollPosition, isMobile, isTablet]);

  useEffect(() => {
    if (showShoppingCart) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showShoppingCart]);

  useEffect(() => {
    if (
      isHolidayPage &&
      deepLinkParams &&
      JSON.parse(
        LocalStorageService.getLocalStorageItem(
          LocalStorageEnum.IS_ALL_DEPARTURES
        )
      )
    ) {
      const [baseUrl, queryString] = deepLinkParams.split("?");
      if (!queryString) return deepLinkParams;
      const searchParams = new URLSearchParams(queryString);
      searchParams.delete(searchParamsEnum.departureLocation);
      const newQueryString = searchParams.toString();
      setNavigateBackUrl(
        newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl
      );
    } else {
      setNavigateBackUrl(deepLinkParams);
    }
  }, [deepLinkParams]);

  const getCleanedRoute = () => {
    let cleanedRoute = route;
    if (route[route.length - 1] === "/") {
      cleanedRoute = route.substring(0, route.length - 1);
    }
    return cleanedRoute;
  };

  const headerComponentElements = (
    <>
      <div className="header-component-content">
        <div className="affiliate-logo-container">
          {affiliateLogoUrl && (
            <a href={affiliateLogoLink}>
              <img src={affiliateLogoUrl} alt="affiliate-logo" />
            </a>
          )}
        </div>
        <div className="language-selector-component-container">
          <LanguageSelectorComponent
            isMobile={isMobile}
            setIsMobile={setIsMobile}
            queryParameters={queryParameters}
            culture={culture}
            language={language}
            handleLanguageChange={handleLanguageChange}
          />
        </div>
      </div>
      {!getCleanedRoute().startsWith(
        `${PagesConstant.BASEPATH}/${PagesConstant.RESULTS}`
      ) &&
      !getCleanedRoute().startsWith(
        `${PagesConstant.BASEPATH}/${PagesConstant.CONFIRMATION}`
      ) ? (
        <a href={navigateBackUrl}>
          <div className="header-arrow-back">
            {GetAffiliate() === affiliates.klm ? (
              <BackIconKLM />
            ) : GetAffiliate() === affiliates.transavia ? (
              <BackIconTransavia />
            ) : null}
            <span>{headerContent && headerContent[0]?.fields?.back}</span>
          </div>
        </a>
      ) : null}
    </>
  );

  return (
    <>
      {!showShoppingCart ? (
        <>
          <div
            className="header-component-container"
            id="header-component-container-id"
          >
            {headerComponentElements}
          </div>
          {(isMobile || isTablet) &&
            checkOutDataCart?.shoppingCart?.priceInfo?.total &&
            getCleanedRoute() !==
              `${PagesConstant.BASEPATH}/${PagesConstant.RESULTS}` &&
            getCleanedRoute() !==
              `${PagesConstant.BASEPATH}/${PagesConstant.HOLIDAY}` && (
              <SummaryBar
                checkoutData={checkOutData}
                className={scrollPosition >= 1 ? "sticky-top" : ""}
                onCartClick={() => setShowShoppingCart(true)}
              />
            )}
        </>
      ) : (
        <div className="overlay-content"></div>
      )}
      {showShoppingCart && (
        <>
          <ShoppingCartComponent
            className={"shopping-cart"}
            checkOutData={checkOutData}
          >
            <CloseCart onClose={() => setShowShoppingCart(false)} />
          </ShoppingCartComponent>
        </>
      )}
      {(getCleanedRoute() ===
        `${PagesConstant.BASEPATH}/${PagesConstant.RESULTS}` ||
        getCleanedRoute() ===
          `${PagesConstant.BASEPATH}/${PagesConstant.HOLIDAY}`) && (
        <CalamityHeader headerContent={headerContent} />
      )}
    </>
  );
};
