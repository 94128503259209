import "./voucher-code.component.scss";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { renderDocument } from "../../services/contentful.service";
import axios from "axios";
import { GetApiUrl } from "../../services/general.service";
import { useDispatch } from "react-redux";
import { apiStatusCodeEnum } from "../../common/enums/apiStatusCodeEnum";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { updateCheckOutDataReducer } from "../../store/shopping/slice";

export const VoucherCodeComponent = ({
  contentfulPaymentPage,
  contentfulButtons,
  checkoutData,
  assignCheckOutData,
  showDownPaymentComponent,
  isVoucherApplied,
  setIsVoucherApplied,
}) => {
  const [voucherCode, setVoucherCode] = useState(null);
  const [isSuccessMessage, setIsSuccessMessage] = useState(null);
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const [isVoucherDeleted, setIsVoucherDeleted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const voucherCodeAPI =
      checkoutData?.additionalProductInfo?.voucherInfo?.voucherCode;
    setVoucherCode(voucherCodeAPI ?? "");
    const voucherSelectedAPI =
      checkoutData?.additionalProductInfo?.voucherInfo?.voucherSelected;
    setIsVoucherApplied(voucherSelectedAPI ?? "");
  }, [checkoutData]);

  const handleVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value);
    setIsSuccessMessage(false);
    setIsErrorMessage(false);
    if (e.target.value !== voucherCode) {
      setIsVoucherApplied(false);
    }
  };

  const handleApplyVoucherCode = async () => {
    setIsSuccessMessage(false);
    setIsErrorMessage(false);
    setIsVoucherDeleted(false);

    try {
      if (checkoutData?.additionalProductInfo?.voucherInfo?.voucherSelected) {
        const response = await axios.delete(
          `${GetApiUrl()}/CheckOut/${checkoutData?.checkOutKey}/DeleteVoucher`
        );

        if (response.status === apiStatusCodeEnum.success) {
          assignCheckOutData(response.data);
          dispatch(updateCheckOutDataReducer(response.data));
          setIsVoucherApplied(true);
          setIsVoucherDeleted(true);
          showDownPaymentComponent(verifyApiResponse(response.data));
        }
      } else {
        const response = await axios.put(
          `${GetApiUrl()}/CheckOut/${
            checkoutData?.checkOutKey
          }/AddVoucher/${voucherCode}`
        );

        if (response.status === apiStatusCodeEnum.success) {
          assignCheckOutData(checkoutData);
          dispatch(updateCheckOutDataReducer(response.data));
          setIsSuccessMessage(true);
          setIsVoucherDeleted(false);
          showDownPaymentComponent(verifyApiResponse(response.data));
        }
      }
    } catch (error) {
      setIsErrorMessage(true);
    }
  };

  const handleOnVoucherLabelDelete = () => {
    setIsVoucherApplied(false);
    setIsVoucherDeleted(false);
  };

  const handleOnSuccessMessageRemove = () => {
    setIsSuccessMessage(false);
  };

  const handleOnErrorMessageRemove = () => {
    setIsErrorMessage(false);
  };

  const verifyApiResponse = (responseData) => {
    const downPaymentPossible = responseData.paymentInfo.downPaymentPossible;
    const downPaymentAmount = responseData.paymentInfo.downPaymentAmount;
    const output =
      downPaymentPossible &&
      !isNaN(parseInt(downPaymentAmount)) &&
      parseInt(downPaymentAmount) !== 0
        ? true
        : false;

    return output;
  };

  return (
    <div className="voucher-code-component">
      {contentfulPaymentPage &&
        renderDocument(
          contentfulPaymentPage[0]?.fields?.voucherCodeDescription
        )}
      <div
        className={`input-component ${isErrorMessage ? "error-voucher" : ""} ${
          isSuccessMessage ? "success-voucher" : ""
        }`}
      >
        <input
          type="text"
          placeholder={voucherCode}
          value={voucherCode ?? ""}
          className={`${isVoucherApplied ? "success-voucher" : ""}`}
          disabled={isVoucherApplied}
          onChange={handleVoucherCodeChange}
        />
      </div>
      <div className="voucher-code-result">
        {isSuccessMessage && (
          <div className="voucher-code-success">
            <div className="voucher-code-description">
              <div className="success-icon">
                <CheckIcon />
              </div>
              <div className="success-description">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.voucherCodeSuccess}
              </div>
            </div>
            <div
              className="voucher-code-remove"
              onClick={handleOnSuccessMessageRemove}
            >
              <CloseIcon />
            </div>
          </div>
        )}
        {isErrorMessage && (
          <div className="voucher-code-error">
            <div className="voucher-code-description">
              <div className="error-icon">
                <ErrorOutlineIcon />
              </div>
              <div className="error-description">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.voucherCodeError}
              </div>
            </div>
            <div
              className="voucher-code-remove"
              onClick={handleOnErrorMessageRemove}
            >
              <CloseIcon />
            </div>
          </div>
        )}
        {isVoucherDeleted && (
          <div className="voucher-code-success">
            <div className="voucher-code-description">
              <div className="success-icon">
                <CheckIcon />
              </div>
              <div className="success-description">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields
                    ?.voucherCodeSuccessfullyDeleted}
              </div>
            </div>
            <div
              className="voucher-code-remove"
              onClick={handleOnVoucherLabelDelete}
            >
              <CloseIcon />
            </div>
          </div>
        )}
      </div>
      <button
        className="voucher-button"
        disabled={!voucherCode}
        onClick={handleApplyVoucherCode}
      >
        {!checkoutData?.additionalProductInfo?.voucherInfo?.voucherSelected
          ? contentfulButtons && contentfulButtons[0]?.fields?.applyDiscountCode
          : contentfulButtons &&
            contentfulButtons[0]?.fields?.removeDiscountCode}
      </button>
    </div>
  );
};
