import { MdLocationPin } from "react-icons/md";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import config from "../../../environments/config.json";
import { GetAmenityDescription } from "../../../common/enums/amenities";
import { PriceWarranty } from "../../price-warranty/price-warranty";
import { GetAffiliate } from "../../../services/general.service"
import { affiliates } from "../../../common/enums/affiliates";
import { isDateDifferenceHigher } from "../../../services/date-formatter.service";
import { useMemo } from "react";


export const HotelAmenitiesComponent = ({ data, contentfulEntries }) => {
  const distanceFromCenter = data.hotel.location.distanceToCenter;
  const structureData = () => {
    const newData = [];
    for (let i = 0; i < config.MAX_AMENITIES_TO_SHOW; i++) {
      if (data.hotel.amenities && data.hotel.amenities[i]) {
        newData.push(data.hotel.amenities[i]);
      }
    }
    return newData;
  };

  const isPriceWarrantyDateValid = useMemo(() => {
    return isDateDifferenceHigher()
  }, [])

  return (
    <div className="hotel-amenities-component">      
      <div className="holiday-card-amenity">
        <MdLocationPin className="icon-box" />
        <span className="distance-from-centre-text">
          {distanceFromCenter +
            " km " +
            (contentfulEntries && contentfulEntries[0]?.fields?.distanceFromCentreText)}
        </span>
      </div>

      { GetAffiliate() === affiliates.transavia && isPriceWarrantyDateValid &&
        <div className="holiday-card-amenity">
          <PriceWarranty />
        </div>
      }
      {structureData().map((amenity, index) => {
        return (
          <div className="holiday-card-amenity" key={index}>
            <CheckOutlinedIcon className="amenity-icon" />
            <span className="amenity-text holiday-card-feature-text">
              {GetAmenityDescription(amenity)}
            </span>
          </div>
        );
      })}
    </div>
  );
};
